<template>
  <!-- PC图表展示页面 -->
    <div class="echarss">
        <!-- 头部筛选 -->
        <div class="header-filter">
            <!-- 左侧搜索 -->
            <div class="filter-left">
                <div class="leftSearchBox" v-show="inputModel.length > 0" v-for="(item, index) in inputModel" :key="index">
                    <!-- 单独文本框 只能是ftype != 2&& -1,筛选类型为0，1 -->
                    <el-input v-if="item.ftype != 2 && item.ftype != 1" :placeholder="item.showName"
                        v-model="item.value" style="margin: 10px; width: 120px" size="mini"></el-input>
                    <el-date-picker v-if="item.ftype == 2 && item.filterType == 2" style="margin: 10px; width: 200px"
                        size="mini" v-model="item.betweenValue" type="daterange" align="right" value-format="yyyy-MM-dd"
                        unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <el-date-picker v-if="item.ftype == 2 && item.filterType != 2" v-model="item.value"
                        style="margin: 10px; width: 140px" size="mini" type="date" placeholder="选择日期">
                    </el-date-picker>
                    <!--  数字框和选择大小 -->
                    <el-select v-if="item.ftype == 1 && item.filterType == 3" v-model="item.compareType"
                        placeholder="比较类型" style="margin: 10px; margin-right: 0; width: 60px" size="mini">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-if="item.ftype == 1" type="number" v-model="item.value" :placeholder="item.showName"
                        style="margin: 10px; width: 120px" size="mini"></el-input>
                </div>
                <!-- <el-button  style="margin:10px;" type="primary" @click="handleSearch">图表</el-button> -->
                <el-button v-if="inputModel.length > 0" style="margin: 10px" type="primary" @click="handleSearch">查找</el-button>
            </div>
            <div class="buttonBox">
                <!-- <el-button style="margin:10px;" v-if="isAllChart" type="primary" @click="switchChart">表格</el-button> -->
                <!-- <el-button style="margin:10px;" v-else type="primary" @click="switchChart">图表</el-button> -->
                <el-radio-group style="margin: 10px" v-model="switchData" size="mini" @change="handleSwitchChange">
                    <el-radio-button label="表格"></el-radio-button>
                    <el-radio-button label="图表"></el-radio-button>
                </el-radio-group>
                <el-button v-if="fieldPropConfigs && fieldPropConfigs.length > 0 && !isAllChart" size="mini"
                    style="margin: 10px; padding-left: 10px; padding-rinht: 10px" type="primary" @click="inputClick">
                    导出
                </el-button>
            </div>
            <!-- 右侧切换图表 -->
            <div class="filter-right">
                <!-- <el-button v-if="isAllChart" type="primary" @click="switchChart">表格</el-button>
                <el-button v-else type="primary" @click="switchChart">图表</el-button> -->

                <!-- 下拉选择图表 -->
                <el-dropdown @command="selectChart">
                    <el-button type="primary">
                        选择对应图表<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in allDataEchart" :key="index" :command="item">
                            {{ item.catName }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <div class="echars">
            <!-- 数据集界面 -->
            <div v-if="fieldPropConfigs && fieldPropConfigs.length > 0 && !isAllChart" style="height: 110%">
                <el-table border :data="tableData" height="80%" stripe style="width: 100%"
                    :summary-method="getSummaries" show-summary @sort-change="sortChange" @row-click="openDetails">
                    <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
                    <el-table-column
                        v-for="(item, index) in fieldPropConfigs"
                        sortable="custom"
                        :key="index"
                        :prop="item.fname"
                        :label="item.showName"
                        :width="item.colWidth ? item.colWidth : '150'"
                        :align="item.ftype == '1' ? 'right' : 'left'" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.backgSpan" class="backgSpanvalue">{{ scope.row[item.fname] }}</span>
                            <span v-else>{{ scope.row[item.fname] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="margin-top: 20px"
                    v-if="fieldPropConfigs && fieldPropConfigs.length > 0 && !isAllChart"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                    :current-page="pageObj.pageNum" :page-sizes="[20, 50, 100, 200]" :page-size="pageObj.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="pageObj.totalRecord">
                </el-pagination>
            </div>
            <!-- 所有图表界面 -->
            <div v-else-if="isAllChart && !biisabled" class="echarsBoxd" :style="{ height: heightData }"
                ref="canvasBox">
                <div v-for="(item, index) in eChartsData" :key="index" class="chartsBox"
                    :style="{ width: item.webConfig.width + 'px', height: item.webConfig.height + 'px', top: item.webConfig.coordinateY + 'px', left: item.webConfig.coordinateX + 'px'}">
                    <div class="searnBox">
                        <!-- <i class="el-icon-search" @click="iconClick(item, index)"></i> -->
                        <el-button @click="chartsDBclick(item)">详情</el-button>
                    </div>
                    <!-- <div class="chartsSunBox" :id="item.webConfig.chartsId" @dblclick="chartsDBclick(item)"></div> -->
                    <div class="chartsSunBox" :id="item.webConfig.chartsId"></div>
                </div>
            </div>
            <div class="nodata" v-else-if="biisabled">暂无数据</div>
        </div>
        <el-drawer title="筛选" :visible.sync="dataDrawer" :modal="false" direction="rtl"
            :before-close="dataDrawerhandleClose" size="20%">
            <el-divider content-position="left">数据源筛选</el-divider>
            <Y-form :dataSourceCondition="dataSourceCondition" :chartsTypeed="chartsType"
                :dataSourceOption="dataSourceOption" @dataSelectChange="dataSelectChange"
                @searchClick="echartsSearchClick" :deleteDisabled="true" />
            <!-- <el-divider content-position="left">条件筛选</el-divider> -->
            <Y-searchPcForm :searchFormInformation="searchFormInformation" @searchClick="searchClick"></Y-searchPcForm>
        </el-drawer>
        <el-drawer title="图表数据" :visible.sync="drawerBottom" direction="btt" :modal="false" size="100%">
            <div class="echarsBox">
                <div class="echBox">
                    <!-- 合计数据 -->
                    <div class="searceBox">
                        <div class="inpiutBox">
                            <el-input size="mini" v-model="tableSearce.top" placeholder="记录数"></el-input>
                        </div>
                        <div class="inpiutBox">
                            <el-select size="mini" v-model="tableSearce.orderBy" placeholder="排序字段">
                                <el-option
                                    v-for="(item, idnex) in sortFieldoptions"
                                    :key="idnex"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="inpiutBox">
                            <el-select size="mini" v-model="tableSearce.isAsc" placeholder="排序方式">
                                <el-option
                                    v-for="(item, idnex) in sortordoptions"
                                    :key="idnex"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="buttonBox">
                            <el-button type="primary" @click="tableClick">查询</el-button>
                        </div>
                    </div>
                    <el-table class="tableBox" ref="echarsTable" @sort-change="echarsSortChange" v-if="sumFieldPropConfigs && sumFieldPropConfigs.length" border :data="sumTableData" height="calc(100% - 110px)" stripe style="width: 100%">
                        <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
                        <el-table-column
                            v-for="(item, index) in sumFieldPropConfigs"
                            sortable="custom"
                            :key="index"
                            :prop="item.fname"
                            :label="item.showName"
                            show-overflow-tooltip
                            :width="item.colWidth ? item.colWidth : '150'"
                        >
                        </el-table-column>
                    </el-table>
                    <div v-else class="tablespanBox">
                        <span>没有数据，数据可能未配置！</span>
                    </div>
                    <el-pagination class="posegBox" style="margin-top: 20px" v-if="sumFieldPropConfigs && sumFieldPropConfigs.length"
                        @size-change="handleSizeChanges" @current-change="handleCurrentChanges" background
                        :current-page="sumPageObj.pageNum" :page-sizes="[20, 50, 100, 200]" :page-size="sumPageObj.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="sumPageObj.totalRecord">
                    </el-pagination>
                </div>
                <div class="echarBox" v-if="drawerBottom">
                    <div class="echbuttonBox">
                        <el-button class="buttond" type="primary" icon="el-icon-download" circle @click="handleDownLoad"></el-button>
                    </div>
                    <div class="echarsSunBox">
                        <div style="height: 100%; width: 100%" :id="momentId"></div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <div v-if="subTableName.length">
            <el-tabs @tab-click="handleClick" type="border-card" v-model="activeName">
                <el-tab-pane v-for="(item, index) in subTableName" :key="index" :label="item.dsName" :name="item.id" style="height: 100%">
                    <el-table border :data="subTableData" v-loading="loading">
                        <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
                        <el-table-column
                            v-for="(items, index) in subTableHeader"
                            :key="index"
                            :label="items.fname"
                            :prop="items.fname"
                            show-overflow-tooltip
                        ></el-table-column>
                    </el-table>
                    <el-pagination
                        style="margin-top: 20px"
                        v-if="subTableData && subTableData.length > 0"
                        @size-change="handleSizeChangess" 
                        @current-change="handleCurrentChangess" 
                        background
                        :current-page="subTableDataList.pageNum" 
                        :page-sizes="[20, 50, 100, 200]" 
                        :page-size="subTableDataList.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" 
                        :total="subTableDataList.totalRecord">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { getChartsList, getChartsData, getdateBaseChartsData, getCharData } from "@/api/moveCharts/moveCharts.js"
import { getChartTablepost, TableSum, exportEcharts, getFilter, getaddParentDataset } from "@/api/chart/index.js"
import { getLocal, setLocal } from '@/utils/methods';
import { getdataSetlist, getChartData, detailDeptree } from "@/api/dataSet/index.js"
import clone from 'clone'
export default {
    name: 'echars',
    data() {
        return {
            tableSearce: {
                top: '',
                orderBy: '',
                isAsc: '',
            },
            sortFieldoptions: [],
            sortordoptions: [
                { label: "升序", value: "1" },
                { label: "降序", value: "0" },
                { label: "默认", value: null },
            ],
            eChartsData: [],
            biisabled: false,
            dataDrawer: false,
            dataSourceCondition: {},
            chartsType: '',
            chartsIndex: 0,
            chartsData: '',
            dataSourceOption: [],
            drawerBottom: false,
            fieldPropConfigs: [],
            tableData: [],
            tableAsc: false,
            sortName: "",
            sumFieldPropConfigs: [],
            sumTableData: [],
            searchChartsData: null,
            searchChartsDataID: '',
            heightData: '1000',
            sumsadd: [],
            xAxisAxisLabel: {
                interval: 0,
                rotate: 30,
                formatter: function (value) {
                    if (value.length > 6) {
                        return value.substring(0, 7) + "...";
                    } else {
                        return value;
                    }
                }
            },
            yAxisAxisLabel: {
                color: "#000",
                interval: 0,
                formatter: function (value) {
                    if (value >= 100 && value < 1000) {
                        return (value / 100) + "百"
                    } else if (value >= 1000 && value < 10000) {
                        return (value / 1000) + "千"
                    } else if (value >= 10000 && value < 100000) {
                        return (value / 10000) + "万"
                    } else if (value >= 100000 && value < 1000000) {
                        return (value / 100000) + "十万"
                    } else if (value >= 1000000 && value < 10000000) {
                        return (value / 1000000) + "百万"
                    } else if (value >= 10000000 && value < 100000000) {
                        return (value / 10000000) + "千万"
                    } else if (value >= 100000000 && value < 1000000000) {
                        return (value / 100000000) + "亿"
                    } else if (value >= 1000000000 && value < 10000000000) {
                        return (value / 1000000000) + "十亿"
                    } else if (value >= 10000000000 && value < 100000000000) {
                        return (value / 10000000000) + "百亿"
                    } else if (value >= 100000000000 && value < 1000000000000) {
                        return (value / 100000000000) + "千亿"
                    } else if (value >= 1000000000000 && value < 10000000000000) {
                        return (value / 1000000000000) + "兆"
                    } else if (value >= 10000000000000 && value < 100000000000000) {
                        return (value / 10000000000000) + "十兆"
                    } else if (value >= 100000000000000 && value < 1000000000000000) {
                        return (value / 100000000000000) + "百兆"
                    } else if (value >= 1000000000000000 && value < 10000000000000000) {
                        return (value / 1000000000000000) + "千兆"
                    } else if (value >= 10000000000000000 && value < 100000000000000000) {
                        return (value / 10000000000000000) + "京"
                    } else if (value >= 100000000000000000 && value < 1000000000000000000) {
                        return (value / 100000000000000000) + "十京"
                    } else if (value >= 1000000000000000000 && value < 10000000000000000000) {
                        return (value / 1000000000000000000) + "百京"
                    } else if (value >= 10000000000000000000 && value < 100000000000000000000) {
                        return (value / 10000000000000000000) + "千京"
                    } else if (value >= 100000000000000000000 && value < 1000000000000000000000) {
                        return (value / 100000000000000000000) + "垓"
                    } else if (value >= 1000000000000000000000 && value < 10000000000000000000000) {
                        return (value / 1000000000000000000000) + "十垓"
                    } else if (value >= 10000000000000000000000 && value < 100000000000000000000000) {
                        return (value / 10000000000000000000000) + "百垓"
                    } else if (value >= 100000000000000000000000 && value < 1000000000000000000000000) {
                        return (value / 100000000000000000000000) + "千垓"
                    } else {
                        return value
                    }
                }
            },
            pageObj: { pageNum: 1, pageSize: 20, totalRecord: 0 },
            sumPageObj: { pageNum: 1, pageSize: 20, totalRecord: 0 },
            searchFormInformation: [],
            echartsSearchOption: {},
            lineHistogramForm: null,
            momentId: '',
            momentWidth: '',
            momentHeight: '',
            momentDataSetId: '',
            isSumData: false,
            base64: '',
            isgetBase64: false,
            tableDataParam: {},
            inputModel: '',
            options: [
                { label: '>', value: '0' },
                { label: '<', value: '1' },
                { label: '=', value: '2' },
                { label: '!=', value: '3' },
                { label: '>=', value: '4' },
                { label: '<=', value: '5' }
            ],
            isAllChart: false,
            DSId: '',
            count: 0,
            allDataEchart: [],
            switchData: '表格',
            //子表的变量
            subTableDataList: { pageNum: 1, pageSize: 20, totalRecord: 0 },
            subTableName: [],//tabs标签页
            subTableHeader: [],//子表表头
            subTableData: [],//子表数据
            activeName: '',//子表ID
            subTableid: '',//主表ID
            ridObj: {},
            currentRowOfMainTable: '',//判断有没点击主表行
            mainTableIndex: {},//主表下标为0的数据
            tabsIndex: 0,//标签页下标
            loading: true,
        }
    },
    props: {
        dataEchart: {
            type: Object,
            default: {}
        },
        allDataEchart: {
            type: Array,
            default: []
        },
        teamids: {
            type: String,
            default: "",
        }
    },
  //监听
    watch: {
        //选择对应图表中有数据触发
        dataEchart: {
            handler: function (val) {
                this.fieldPropConfigs = []
                if (val.canvasHeight !== "" && val.canvasHeight !== null && val.canvasHeight !== undefined) {
                    this.heightData = val.canvasHeight + 'px'
                } else {
                    this.heightData = '100%'
                }
                this.echartsId = val.id
                this.eChartsData = []
                // this.DSId = ''
                this.tableDataParam = {
                    datasetId: "",
                    filterPropList: "",
                }
                if (val) {
                    this.inputModel = []
                    this.init(val, true)
                } else {
                    this.inputModel = []
                    this.tableData = []
                }
                // this.getFilters(val.id)
            },
            deep: true,
        },
        //选择对应图表中没有数据触发
        teamids: {
            handler: function (val) {
                if (val !== null && val !== undefined && val != "") {
                    /* 获取表格数据  2222 */
                    let data = {
                        datasetId: val,
                        filterPropList: []
                    }
                    this.pageObj.pageNum = 1
                    this.pageObj.pageSize = 20                   
                    this.getFilters(val)
                    setTimeout(() => {
                        let filterSearch = this.inputModel.map((item) => {
                            let obj = {
                                betweenValue: item.betweenValue,
                                compareType: item.compareType,
                                datePrecision: item.datePrecision,
                                filterType: item.filterType,
                                fname: item.fname,
                                id: item.id,
                                value: item.value
                            }
                            return obj
                        })
                        data.filterPropList = filterSearch
                        console.log("走了4", data)
                        this.tableDataParam.datasetId = data.datasetId
                        this.getTableData(data)
                        this.secondaryDataSet(data.datasetId)
                    }, 0);
                }
            },
            deep: true,
            immediate: true,
        },
        //选择对应图表中的下拉数据
        allDataEchart: {
            handler: function (val) {
                this.allDataEchart = val
            },
            deep:true,
            immediate: true
        }
    },
    computed: {},
    created() {
        //判断有没有对应列表teamID
        if (getLocal("teamID")) {
            let teamID = JSON.parse(getLocal("teamID"))
            this.echartsId = teamID
            // this.getEcharts(teamID)
        }
        getdataSetlist().then((res) => {
            this.dataSourceOption = res
        })
        if (getLocal("heightList")) {
            let heightListed = JSON.parse(getLocal("heightList"))
            this.$nextTick(() => {
                // this.heightData = heightListed + "px"
                this.heightData = heightListed + 'px'
            })
        } else {
            this.$nextTick(() => {
                this.heightData = '100%'
            })
        }
    },
    mounted() {},

    methods: {
        openDetails(row, column) {
            this.currentRowOfMainTable = row
            let baseArr = [], newArr = [];
            if (this.subTableName.length) {
                this.subTableName.forEach((t) => baseArr.push(t?.referCondition))
                this.activeName = this.subTableName[0].id
            }
            newArr = baseArr.filter((str) => str.includes("@"))
            newArr = newArr.map((item) => {
                return item = item.split('@')[1]
            })
            newArr.forEach((str) => row?.[str] ? (this.ridObj[str] = row[str]) : null)
            this.handleClick({index:this.tabsIndex})
        },
        //子表标签页事件
        handleClick(tab, event) {
            this.tabsIndex = tab.index
            this.activeName = this.subTableName[this.tabsIndex].id
            let obj = {}
            obj.rid = Object.keys(this.ridObj)[this.tabsIndex];
            obj.ridvalue = Object.values(this.ridObj)[this.tabsIndex];
            let paramss = {
                id: this.subTableName[this.tabsIndex].id,
                pageNum: this.subTableDataList.pageNum,
                pageSize: this.subTableDataList.pageSize,
            };
            if (JSON.stringify(this.ridObj) !== "{}") paramss = Object.assign(paramss, obj);
            getChartTablepost(paramss, this.subTableHeader).then((res) => {
                this.loading = false;
                this.subTableDataList.pageNum = res.pageNum
                this.subTableDataList.pageSize = res.pageSize
                this.subTableDataList.totalRecord = res.totalRecord
                this.subTableHeader = res.fieldPropConfigs
                this.subTableData = res.result
                this.$forceUpdate()
            })
        },
        // 表格排序数列查询
        tableClick() {
            this.getSumData(this.tableSearce)
            this.$nextTick(() => {
                // this.chartsDBclick(this.chartsData, sortData)
                let echaData = {
                    chartSet: this.chartsData.chartSet,
                    datasetId: this.chartsData.datasetId,
                    filters: [],
                    type: this.chartsData.chartsType,
                    isAsc: this.tableSearce && this.tableSearce.isAsc && this.tableSearce.isAsc === "1" ? true : this.tableSearce && this.tableSearce.isAsc && this.tableSearce.isAsc === "0" ? false : null,
                    orderBy: this.tableSearce && this.tableSearce.orderBy ? this.tableSearce.orderBy : "",
                    top: this.tableSearce && this.tableSearce.top ? this.tableSearce.top * 1 : null,
                }
                let echaId = this.chartsData.datasetId
                this.echartsList(echaData, echaId, this.chartsData)
            })
        },
        // 图表表格排序
        echarsSortChange(column, prop, order) {
            let sortData = {
                isAsc: null,
                orderBy: ""
            }
            if (column.order == "ascending") {
                sortData.isAsc = "1"
                sortData.orderBy = column.column.property
            } else if (column.order == "descending") {
                sortData.isAsc = "0"
                sortData.orderBy = column.column.property
            } else {
                sortData.isAsc = null
                sortData.orderBy = ""
            }
            this.getSumData(sortData)
            this.$nextTick(() => {
                // this.chartsDBclick(this.chartsData, sortData)
                let echaData = {
                    chartSet: this.chartsData.chartSet,
                    datasetId: this.chartsData.datasetId,
                    filters: [],
                    type: this.chartsData.chartsType,
                    isAsc: sortData && sortData.isAsc && sortData.isAsc === "1" ? true : sortData && sortData.isAsc && sortData.isAsc === "0" ? false : null,
                    orderBy: sortData && sortData.orderBy ? sortData.orderBy : "",
                }
                let echaId = this.chartsData.datasetId
                this.echartsList(echaData, echaId, this.chartsData)
            })
        },
        // 主表排序
        sortChange(columns) {
            if (columns.order == "ascending") {
                this.tableAsc = true
                this.sortName = columns.prop
            } else if (columns.order == "descending") {
                this.tableAsc = false
                this.sortName = columns.prop
            } else {
                this.tableAsc = null
                this.sortName = ""
            }
            this.getTableData(this.tableDataParam)
        },
        // 打印
        inputClick() {
            let dataQueryDTO = {
                datasetId: "",
                filters: [],
            }
            dataQueryDTO.datasetId = this.tableDataParam.datasetId
            let filterSearch = this.inputModel.map((item) => {
                let obj = {
                    betweenValue: item.betweenValue,
                    compareType: item.compareType,
                    datePrecision: item.datePrecision,
                    filterType: item.filterType,
                    fname: item.fname,
                    id: item.id,
                    value: item.value
                }
                return obj
            })
            dataQueryDTO.filters = filterSearch
            exportEcharts({ dataQueryDTO }).then((res) => {
                const content = res.data
                const blob = new Blob([content]) // 构造一个blob对象来处理数据
                let catName = JSON.parse(getLocal("catName"))
                const fileName = catName + '.xlsx' // 导出文件名
                // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                // IE10以上支持blob但是依然不支持download
                if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
                const link = document.createElement('a') // 创建a标签
                link.download = fileName // a标签添加属性
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click() // 执行下载
                URL.revokeObjectURL(link.href) // 释放url
                document.body.removeChild(link) // 释放标签
                } else { // 其他浏览器
                navigator.msSaveBlob(blob, fileName)
                }
            })
        },
        // 主表合计事件
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            let isTotaladd = this.fieldPropConfigs.filter(function (item) {
                return item.isTotal
            })
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                for (let i = 0; i < isTotaladd.length; i++) {
                    if (column.property === isTotaladd[i].fname) {
                        sums[index] = toThousands(this.sumsadd[column.property], 2)
                    }
                }
            });
            return sums;
            // 千分位转换事件
            function toThousands(num, expressionDecimalDigit) {
                let newnum = ""
                if (num === "" || num === undefined || num === null) {
                    newnum = ""
                } else {
                    newnum = num + ""
                }
                if (expressionDecimalDigit && expressionDecimalDigit !== 0) {
                    let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : Number(newnum)?.toFixed(expressionDecimalDigit) + "").split('.');
                    sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
                    return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
                } else {
                    let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : newnum).split('.');
                    sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
                    return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
                }
            }
        },
        // 初始页面事件
        init(val, isTrue) {
            this.getEcharts(val.id, isTrue)
        },
        // 获取echarts图事件
        getEcharts(iteamId, isTrue, filter) {
            let params = { catId: iteamId }
            getChartsList(params).then((res) => {
                let data = []
                for (let i = 0; i < res.length; i++) {
                    let parameters = {
                        chartSet: {},
                        datasetId: "",
                        filters: filter?.length ? filter : [],
                        type: ""
                    }
                    parameters.chartSet = res[i].chartSet
                    parameters.datasetId = res[i].datasetId
                    parameters.type = res[i].chartsType
                    data.push(parameters)
                }
                // if (!res[0].datasetId) {
                //     getFilter({id: iteamId}).then(rel=> {
                //         this.inputModel = rel
                //     })
                // }
                let newres = res
                if (isTrue || this.count == 0) {
                    this.count++
                    getFilter({ id: res[0].datasetId }).then((rel) => {
                        this.inputModel = rel
                    })
                }
                // getFilter({id: iteamId}).then(rel=> {
                //     this.inputModel = rel
                // })
                this.tableDataParam = {
                    datasetId: res[0].datasetId,
                    filterPropList: res[0].filterPropList
                }
                /* 获取表格数据  2222 */
                console.log("走了6")
                this.getTableData(res[0])
                this.secondaryDataSet(res[0].datasetId)
                /* 最新获取图表数据  */
                getdateBaseChartsData(data, res[0].datasetId).then((res) => {
                    for (let i = 0; i < newres.length; i++) {
                        newres[i].options = this.chartsDataProcessing(newres[i], res[i])
                        newres[i].chartSet.dataset = newres[i].datasetId
                    }
                    this.eChartsData = newres
                    this.$nextTick(() => {
                        for (let i = 0; i < newres.length; i++) {
                            this.$nextTick(() => {
                                this.chartsRender(newres[i].webConfig.chartsId, newres[i].options, newres[i].chartsType)
                            })
                        }
                    })
                }).catch((res) => {
                    // this.$message({
                    //     message: '数据获取失败，可能部分图表未配置数据。',
                    //     type: 'warning'
                    // })
                    for (let i = 0; i < newres.length; i++) {
                        newres[i].options = this.chartsDataProcessing(newres[i], null)
                        newres[i].chartSet.dataset = newres[i].datasetId
                    }
                    this.eChartsData = newres
                    this.$nextTick(() => {
                        for (let i = 0; i < newres.length; i++) {
                            this.$nextTick(() => {
                                this.chartsRender(newres[i].webConfig.chartsId, newres[i].options, newres[i].chartsType)
                            })
                        }
                    })
                })
                /* 获取下拉框的数据 */
                getChartData({ datasetId: res[0].datasetId }).then((res) => {
                    this.allDataEchart = res
                })
            })
        },
        getTableData(item) {
            /* 获取表格数据 */
            let params = {
                id: item.datasetId,
                pageNum: this.pageObj.pageNum,
                pageSize: this.pageObj.pageSize,
                orderBy: this.sortName,
                asc: this.tableAsc
            }
            if (!item.filterPropList) {
                item.filterPropList = []
            }
            this.$store.commit('setMyData', {
                params,
                filterPropList: item.filterPropList
            })
            getChartTablepost(params, item.filterPropList).then((res) => {
                this.mainTableIndex = JSON.parse(JSON.stringify(res.result[0]))
                this.sumsadd = res.sums
                this.fieldPropConfigs = res.fieldPropConfigs
                let numberadd = res.fieldPropConfigs.filter(function (item) {
                    return item.ftype == 1 || item.ftype == 3
                })
                let newSubSums = subSumsFunction(res.subSums, res.result)
                this.tableData = newSubSums.map(function (item) {
                    for (let i in item) {
                        for (let j = 0; j < numberadd.length; j++) {
                            if (i == numberadd[j].fname) {
                                item[i] = toThousands(item[i])
                            }
                        }
                    }
                    return item
                })
                this.pageObj.totalRecord = res.totalRecord
                this.pageObj.pageNum = res.pageNum
                this.pageObj.pageSize = res.pageSize
            })
            function subSumsFunction(subSums, result) {
                if (subSums.length == 0) {
                    return result
                } else {
                    let add = []
                    for (let i = 0; i < subSums.length; i++) {
                        add[i] = []
                        let subTotalGrouplength = 0
                        for (let j in subSums[i].subTotalGroup) {
                            subTotalGrouplength++
                        }
                        for (let k = 0; k < result.length; k++) {
                            let subTotalGroupcount = 0
                            for (let j in subSums[i].subTotalGroup) {
                                if (result[k][j] === subSums[i].subTotalGroup[j]) {
                                    subTotalGroupcount++
                                }
                                if (subTotalGroupcount === subTotalGrouplength) {
                                    add[i].push(result[k])
                                }
                            }
                        }
                        let data = subSums[i].subTotal
                        data.backgSpan = true
                        add[i].push(data)
                    }
                    // for (let i = 0; i < add.length; i ++) {}
                    let arr = add.reduce(function (a, b) {
                        return a.concat(b)
                    });
                    return arr
                }
            }
            // 千分位转换事件
            function toThousands(num, expressionDecimalDigit) {
                let newnum = ""
                if (num === "" || num === undefined || num === null) {
                    newnum = ""
                } else {
                    newnum = num + ""
                }
                if (expressionDecimalDigit && expressionDecimalDigit !== 0) {
                    let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : Number(newnum)?.toFixed(expressionDecimalDigit) + "").split('.');
                    sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
                    return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
                } else {
                    let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : newnum).split('.');
                    sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
                    return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
                }
            }
        },
        handleSwitchChange(e) {
            // this.inputModel.forEach(item => {
            //     item.value = '',
            //     item.betweenValue = null
            // })
            let teamID = getLocal("teamID")
            this.$nextTick(() => {
                if (teamID == '""') {
                    if (this.switchData == "表格") {
                        if (this.fieldPropConfigs.length > 0) {
                            this.isAllChart = false
                            this.biisabled = false
                        } else {
                            this.isAllChart = true
                            this.biisabled = true
                        }
                    } else {
                        if (this.eChartsData.length == 0) {
                            this.isAllChart = true
                            this.biisabled = true
                        } else {
                            this.isAllChart = false
                            this.biisabled = false
                        }
                    }
                    // this.fieldPropConfigs = []
                    // this.tableData = []
                    // if (this.eChartsData.length == 0) {
                    //     this.isAllChart = true
                    //     this.biisabled = true
                    // } else {
                    //     this.isAllChart = false
                    //     this.biisabled = false
                    // }
                    // tableData
                } else {
                    this.biisabled = false
                    this.isAllChart = !this.isAllChart
                    let teamIDed = JSON.parse(getLocal("teamID"))
                    this.echartsId = teamIDed
                    this.handleSearch()
                    // this.getEcharts(teamID)
                }
            })
            // getdataSetlist().then(res => {
            //     this.dataSourceOption = res
            // })
            if (getLocal("heightList")) {
                let heightListed = JSON.parse(getLocal("heightList"))
                this.$nextTick(() => {
                    // this.heightData = heightListed + "px"
                    this.heightData = heightListed + 'px'
                })
            } else {
                this.$nextTick(() => {
                    this.heightData = '100%'
                })
            }
        },
        /* 下了选择同一个数据集的不同图表 */
        selectChart(item) {
            setLocal("heightList", item.canvasHeight)
            setLocal("teamID", item.id)
            this.dataEchart = item
        },
        getFilters(id) {
            getFilter({ id: id }).then((rel) => {
                this.inputModel = rel
            })
        },
        chartsDataProcessing(item, chartsOption) {
            let option
            if (item.chartsType == 'histogram') {
                let data1 = []
                let data2 = []
                if (chartsOption && chartsOption?.length) {
                    for (let i = 0; i < chartsOption?.length; i++) {
                        data1.push(chartsOption[i].name)
                        data2.push(chartsOption[i].value)
                    }
                } else {
                    data1 = ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"];
                    data2 = [5, 20, 36, 10, 10, 20]
                }
                option = {
                    title: {
                        text: item.webConfig.title
                    },
                    tooltip: {},
                    legend: {
                        top: '7%'
                    },
                    xAxis: {
                        data: data1,
                        axisLabel: this.xAxisAxisLabel,
                    },
                    yAxis: {
                        axisLabel: this.yAxisAxisLabel,
                    },
                    series: [{
                        type: 'bar',
                        data: data2
                    }]
                }
            } else if (item.chartsType == 'charDSJZ') {
                let typedata = []
                if (chartsOption && chartsOption?.dimensions?.length) {
                    let newdata = clone(chartsOption.dimensions)
                    for (let i = 0; i < newdata.length - 1; i++) {
                        let data = {
                            type: 'bar'
                        }
                        typedata.push(data)
                    }
                } else {
                    typedata = [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                }
                option = {
                    title: {
                        text: item.webConfig.title
                    },
                    legend: {
                        top: '7%'
                    },
                    tooltip: {},
                    dataset: {
                        dimensions: chartsOption && chartsOption.dimensions ? chartsOption.dimensions : ['product', '2015', '2016', '2017'],
                        source: chartsOption && chartsOption.source ? chartsOption.source : [
                            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                            { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                            { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                            { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: this.xAxisAxisLabel,
                    },
                    yAxis: {
                        axisLabel: this.yAxisAxisLabel,
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: typedata
                }
            } else if (item.chartsType == 'contrastDSJZ') {
                let typedata = []
                if (chartsOption && chartsOption?.dimensions?.length) {
                    let newdata = clone(chartsOption.dimensions)
                    for (let i = 0; i < newdata.length - 1; i++) {
                        let data = {
                            type: 'bar'
                        }
                        typedata.push(data)
                    }
                } else {
                    typedata = [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                }
                option = {
                    title: {
                        text: item.webConfig.title
                    },
                    legend: {
                        top: '7%'
                    },
                    tooltip: {},
                    dataset: {
                        dimensions: chartsOption && chartsOption.dimensions ? chartsOption.dimensions : ['product', '2015', '2016', '2017'],
                        source: chartsOption && chartsOption.source ? chartsOption.source : [
                            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                            { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                            { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                            { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: this.xAxisAxisLabel,
                    },
                    yAxis: {
                        axisLabel: this.yAxisAxisLabel,
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: typedata
                }
            } else if (item.chartsType == 'lineChart') {
                let data1 = []
                let data2 = []
                if (chartsOption && chartsOption?.length) {
                    for (let i = 0; i < chartsOption.length; i++) {
                        data1.push(chartsOption[i].name)
                        data2.push(chartsOption[i].value)
                    }
                } else {
                    data1 = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    data2 = [150, 230, 224, 218, 135, 147, 260]
                }
                option = {
                    title: {
                        text: item.webConfig.title
                    },
                    legend: {
                        top: '7%'
                    },
                    xAxis: {
                        type: 'category',
                        data: data1,
                        axisLabel: this.xAxisAxisLabel,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: this.yAxisAxisLabel,
                    },
                    series: [
                        {
                        data: data2,
                        type: 'line'
                        }
                    ]
                }
            } else if (item.chartsType == 'lineHistogram') {
                let series = []
                if (chartsOption && chartsOption?.cols?.length) {
                    series = chartsOption.cols
                } else {
                    series = [
                        {
                            name: 'Evaporation',
                            type: 'bar',
                            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
                        },{
                            name: 'Precipitation',
                            type: 'bar',
                            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
                        },{
                            name: 'Temperature',
                            type: 'line',
                            yAxisIndex: 1,
                            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                        }
                    ]
                }
                let rows = []
                if (chartsOption && chartsOption?.rows?.length) {
                    rows = chartsOption.rows
                } else {
                    rows = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                }
                let legend = []
                let yAxis = []
                if (chartsOption && chartsOption?.cols?.length) {
                    legend = chartsOption.cols.map((item) => item.name)
                    yAxis = chartsOption.cols.map((item) => {
                        let obj = {
                            type: 'value',
                            name: item.name,
                            axisLabel: this.yAxisAxisLabel,
                        }
                        return obj
                    })
                } else {
                    legend = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    yAxis = [
                        { type: 'value', name: 'Precipitation', min: 0, max: 250, interval: 50,
                            axisLabel: { formatter: '{value} ml' }
                        },
                        { type: 'value', name: 'Temperature', min: 0, max: 25, interval: 5,
                            axisLabel: { formatter: '{value} °C' }
                        }
                    ]
                }
                option = {
                    title: {
                        text: item.webConfig.title
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'cross', crossStyle: { color: '#999' } }
                    },
                    legend: { data: legend, top: '7%' },
                    xAxis: [
                        {
                            type: 'category',
                            data: rows,
                            axisPointer: { type: 'shadow' },
                            axisLabel: this.xAxisAxisLabel,
                        }
                    ],
                    yAxis: yAxis,
                    series: series
                }
            } else if (item.chartsType == 'charbB') {
                let dataList = []
                if (chartsOption && chartsOption?.length) {
                    for (let i = 0; i < chartsOption.length; i++) {
                        let newdata = {
                            value: chartsOption[i].value,
                            name: chartsOption[i].name,
                        }
                        dataList.push(newdata)
                    }
                } else {
                    dataList = [
                        { value: 1048, name: 'Search Engine' },
                        { value: 735, name: 'Direct' },
                        { value: 580, name: 'Email' },
                        { value: 484, name: 'Union Ads' },
                        { value: 300, name: 'Video Ads' }
                    ]
                }
                option = {
                    title: { text: item.webConfig.title },
                    tooltip: { trigger: 'item' },
                    legend: { type: 'scroll', orient: 'vertical', top: 20, bottom: 20, right:  10, },
                    series: [
                        { name: 'Access From', type: 'pie', radius: '80%',
                            center: ['40%' , '50%'],
                            data: dataList,
                        }
                    ]
                }
            } else if (item.chartsType == 'linkageShare') {
                let newdata = []
                if (chartsOption && chartsOption?.source?.length) {
                    for (let i = 0; i < chartsOption.source.length - 1; i++) {
                        let datalist = {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' }
                        }
                        newdata.push(datalist)
                    }
                    let linedata = {
                        type: 'pie',
                        id: 'pie',
                        radius: '50%',
                        center: ['45%', '25%'],
                        emphasis: {
                            focus: 'self'
                        },
                        label: {
                            formatter: '{b}: {@' + chartsOption.source[0][1] + '} ({d}%)'
                        },
                        encode: {
                            itemName: chartsOption.source[0][0],
                            value: chartsOption.source[0][1],
                            tooltip: chartsOption.source[0][1]
                        }
                    }
                    newdata.push(linedata)
                } else {
                    newdata = [
                        { type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } },
                        { type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } },
                        { type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } },
                        { type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } },
                        { type: 'pie', id: 'pie', radius: '50%',
                            center: ['50%', '25%'],
                            emphasis: { focus: 'self' },
                            label: { formatter: '{b}: {@2012} ({d}%)' },
                            encode: { itemName: 'product', value: '2012', tooltip: '2012' }
                        }
                    ]
                }
                option = {
                    title: { text: item.webConfig.title },
                    legend: { top: '7%' },
                    tooltip: { trigger: 'axis', showContent: false },
                    dataset: {
                        source: chartsOption && chartsOption.source ? chartsOption.source : [
                            ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
                            ['Milk Tea', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
                            ['Matcha Latte', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
                            ['Cheese Cocoa', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
                            ['Walnut Brownie', 25.2, 37.1, 41.2, 18, 33.9, 49.1]
                        ]
                    },
                    xAxis: { type: 'category', axisLabel: this.xAxisAxisLabel, },
                    yAxis: { gridIndex: 0, axisLabel: this.yAxisAxisLabel, },
                    grid: { top: '55%' },
                    series: newdata,
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        top: 20,
                        bottom: 20,
                        right: 10,
                    },
                }
            }
            return option
        },
        dataSelectChange (form) {
            if(Array.isArray(form?.bar) && Array.isArray(form?.line)) {
                this.lineHistogramForm = form
            }
        },
        iconClick (item, idnex) {
            this.echartsSearchOption = clone(item)
            this.chartsIndex = clone(idnex)
            this.dataSourceCondition = clone(item.chartSet)
            this.searchFormInformation = clone(item.filterPropList)
            if (item.chartsType == 'histogram') {
                this.chartsType = 'histogram'
            } else if (item.chartsType == 'charDSJZ') {
                this.chartsType = 'charDSJZ'
            }else if(item.chartsType == 'contrastDSJZ') {
                this.chartsType = 'contrastDSJZ'
            } else if (item.chartsType == 'lineChart') {
                this.chartsType = 'lineChart'
            } else if (item.chartsType == 'lineHistogram') {
                this.chartsType = 'lineHistogram'
            } else if (item.chartsType == 'charbB') {
                this.chartsType = 'charbB'
            } else if (item.chartsType == 'linkageShare') {
                this.chartsType = 'linkageShare'
            }
            this.dataDrawer = true
        },
        dataDrawerhandleClose() {
            this.dataDrawer = false
        },
        chartsRender (boxId, boxOption, type) {
            var chartDom = document.getElementById(boxId);
            var myChart = echarts.init(chartDom);
            var option = boxOption
            if (type == "linkageShare") {
                setTimeout(() => {
                    myChart.on('updateAxisPointer', function (event) {
                        const xAxisInfo = event.axesInfo[0];
                        if (xAxisInfo) {
                        const dimension = xAxisInfo.value + 1;
                        myChart.setOption({
                            series: {
                            id: 'pie',
                            label: {
                                formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                            },
                            encode: {
                                value: dimension,
                                tooltip: dimension
                            }
                            }
                        });
                        }
                    });
                    if(this.isgetBase64) {
                        setTimeout(() => {
                            this.base64 = myChart.getDataURL({
                                pixelRatio: 2,
                                type: 'png',
                                backgroundColor: '#fff'
                            })
                        },1000)
                      this.isgetBase64 = false
                    }
                    myChart.setOption(option);
                });
            } else {
                myChart.hideLoading()
                if(this.isgetBase64) {
                    setTimeout(() => {
                        this.base64 = myChart.getDataURL({
                            pixelRatio: 2,
                            type: 'png',
                            backgroundColor: '#fff'
                        })
                    },1000)
                    this.isgetBase64 = false
                }
                myChart.setOption(option, true);
            }
            // let listener = function () {
            //     myChart.resize()
            // };
            // EleResize.on(chartDom, listener)
        },
        // 一页大小
        handleSizeChange(val) {
            this.pageObj.pageSize = val
            this.getTableData(this.tableDataParam)
        },
        // 点击页码
        handleCurrentChange(val) {
            this.pageObj.pageNum = val
            this.getTableData(this.tableDataParam)
        },
        // 合计一页大小
        handleSizeChanges(val) {
            this.sumPageObj.pageSize = val
            this.getSumData()
        },
        // 合计页码
        handleCurrentChanges(val) {
            this.sumPageObj.pageNum = val
            this.getSumData()
        },
        // 合计一页大小
        handleSizeChangess(val) {
            this.subTableDataList.pageSize = val
            this.secondaryDataSet()
        },
        // 合计页码
        handleCurrentChangess(val) {
            this.subTableDataList.pageNum = val
            this.secondaryDataSet()
        },
        // 获取单独图表数据
        echartsList (data, echartsId, item) {
            let echartadd = []
            echartadd.push(data)
            getdateBaseChartsData(echartadd, echartsId).then(res => {
                let newitem = clone(item)
                let option = this.chartsDataProcessing(newitem, res[0])
                item.options = {}
                item.options = clone(option)
                this.getChartTableFunction(item)
            })
        },
        // 点击详情
        chartsDBclick (item) {
            this.chartsData = clone(item)
            //444
            this.isgetBase64 = true
            let echaData = {
                chartSet: item.chartSet,
                datasetId: item.datasetId,
                filters: [],
                type: item.chartsType,
            }
            let echaId = item.datasetId
            this.echartsList(echaData, echaId, item)
            this.getChartTableFunction(item)
            this.getSumData()
        },
        getChartTableFunction (data) {
            let datasetId = ""
            if (data && data.datasetId) {
                datasetId = data.datasetId
            } else {
                datasetId = data.dataset
            }
            this.fieldPropConfigs = []
            this.tableData = []
            for (let i = 0; i < data.filterPropList.length; i ++) {
                data.filterPropList[i].betweenValue = null
            }
            this.momentId = data.webConfig.chartsId + 's'
            this.momentDataSetId = data.datasetId
            this.drawerBottom = true
            // this.getSumData(sortData)
            this.$nextTick(() => {
                this.chartsRender(data.webConfig.chartsId + 's', data.options, data.chartsType)
            })
        },
        // 条件搜索
        searchClick (data) {
            delete this.echartsSearchOption.chartSet.dataset
            let chartsSearch = {
                chartSet: this.echartsSearchOption.chartSet,
                datasetId: this.echartsSearchOption.datasetId,
                filters: data,
                type: this.echartsSearchOption.chartsType,
            }
            getCharData(chartsSearch).then(res => {
                this.eChartsData[this.chartsIndex].options = this.chartsDataProcessing(this.eChartsData[this.chartsIndex], res)
                this.$nextTick(() => {
                    this.chartsRender(this.eChartsData[this.chartsIndex].webConfig.chartsId, this.eChartsData[this.chartsIndex].options)
                })
                // this.eChartsData[this.chartsIndex].
            })
        },
        // 数据源搜索
        echartsSearchClick (data) {
            let newdata = clone(data)
            // 如果包含bars和lines都是数组就是折线和柱状图
            if(Array.isArray(newdata?.bars) && Array.isArray(newdata?.lines)) {
                if(this.lineHistogramForm != null) {
                    newdata = this.lineHistogramForm
                }else {
                    newdata = this.dataSourceCondition
                }
            }
            let chartsData = clone(this.echartsSearchOption)
            let chartsDataID = newdata.dataset
            delete newdata.dataset
            let chartsSearch = {
                chartSet: newdata,
                datasetId: chartsDataID,
                filters: chartsData.filterPropList,
                type: chartsData.chartsType,
            }
            this.searchChartsData = newdata
            this.searchChartsDataID = chartsDataID
            getCharData(chartsSearch).then(res => {
                this.eChartsData[this.chartsIndex].options = this.chartsDataProcessing(this.eChartsData[this.chartsIndex], res)
                this.$nextTick(() => {
                    this.chartsRender(this.eChartsData[this.chartsIndex].webConfig.chartsId, this.eChartsData[this.chartsIndex].options)
                })
            })
        },
        getSumData(sortData) {
            this.sumTableData = []
            // if(this.isSumData) {
            let params = {
                id: this.momentDataSetId,
                pageNum: this.sumPageObj.pageNum,
                pageSize: this.sumPageObj.pageSize,
            };
            let parameters = {
                chartSet: {},
                datasetId: "",
                filters: [],
                type: "",
                isAsc: sortData && sortData.isAsc && sortData.isAsc === "1" ? true : sortData && sortData.isAsc && sortData.isAsc === "0" ? false : null,
                orderBy: sortData && sortData.orderBy ? sortData.orderBy : "",
                top: sortData && sortData.top ? sortData.top * 1 : null,
            }
            parameters.chartSet = this.chartsData.chartSet
            parameters.datasetId = this.chartsData.datasetId
            parameters.type = this.chartsData.chartsType
            TableSum(params, parameters).then((res) => {
                if (res.result.length) {
                    this.sumPageObj = {
                        pageNum: res.pageNum,
                        pageSize: res.pageSize,
                        totalRecord: res.totalRecord
                    }
                    this.sumTableData = res.result
                    this.sumFieldPropConfigs = []
                    for (let i in res.result[0]) {
                        for (let j = 0; j < res.fieldPropConfigs.length; j++) {
                            if (i == res.fieldPropConfigs[j].fname) {
                                this.sumFieldPropConfigs.push(res.fieldPropConfigs[j])
                                continue;
                            }
                        }
                    }
                    this.sortFieldoptions = []
                    for (let i = 0; i < this.sumFieldPropConfigs.length; i++) {
                        let data = {
                            label: this.sumFieldPropConfigs[i].fname,
                            value: this.sumFieldPropConfigs[i].fname,
                        }
                        this.sortFieldoptions.push(data)
                    }
                }
                this.$refs.echarsTable.doLayout()
            })
            // }
        },
        handleDownLoad() {
            let dataQueryDTO = {
                chartSet: {},
                datasetId: "",
                filters: [],
                type: ""
            }
            dataQueryDTO.chartSet = this.chartsData.chartSet
            dataQueryDTO.datasetId = this.chartsData.datasetId
            dataQueryDTO.type = this.chartsData.chartsType

            // chartimg
            let index = this.base64.indexOf(",")
            let chartImgStr = this.base64.slice(index + 1)
            // let formData = new FormData()
            // formData.append('chart_img', chart_img)
            // formData.append('dataQueryDTO', dataQueryDTO)
            exportEcharts({ chartImgStr, dataQueryDTO }).then((res) => {
                const content = res.data
                const blob = new Blob([content]) // 构造一个blob对象来处理数据
                const fileName = 'monthAssess.xlsx' // 导出文件名
                // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                // IE10以上支持blob但是依然不支持download
                if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
                    const link = document.createElement('a') // 创建a标签
                    link.download = fileName // a标签添加属性
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click() // 执行下载
                    URL.revokeObjectURL(link.href) // 释放url
                    document.body.removeChild(link) // 释放标签
                } else { // 其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
            })
        },
        handleSearch() {
            let filterSearch = this.inputModel.map((item) => {
                let obj = {
                    betweenValue: item.betweenValue,
                    compareType: item.compareType,
                    datePrecision: item.datePrecision,
                    filterType: item.filterType,
                    fname: item.fname,
                    id: item.id,
                    value: item.value
                }
                return obj
            })
            this.tableDataParam.filterPropList = filterSearch
            if (this.isAllChart) {
                let teamID = JSON.parse(getLocal("teamID"))
                this.getEcharts(teamID, false, this.tableDataParam.filterPropList)
            } else {
                this.getTableData(this.tableDataParam)
            }
        },
        switchChart() {
            this.isAllChart = !this.isAllChart
            // this.inputModel.forEach(item => {
            //     item.value = '',
            //     item.betweenValue = null
            // })
            if (getLocal("teamID")) {
                let teamID = JSON.parse(getLocal("teamID"))
                this.echartsId = teamID
                this.getEcharts(teamID)
            }
            getdataSetlist().then((res) => {
                this.dataSourceOption = res
            })
            if (getLocal("heightList")) {
                let heightListed = JSON.parse(getLocal("heightList"))
                this.$nextTick(() => {
                    // this.heightData = heightListed + "px"
                    this.heightData = heightListed + 'px'
                })
            } else {
                this.$nextTick(() => {
                    this.heightData = '100%'
                })
            }
        },
        //展示页面,子表数据
        secondaryDataSet(val) {
            setTimeout(() => {
                this.subTableid = val
                let ids = { id: val }
                detailDeptree(ids).then((res) => {
                    this.subTableName = res.children;
                    if(this.subTableName.length > 0) {
                        if(this.currentRowOfMainTable == '' || this.currentRowOfMainTable == null || this.currentRowOfMainTable == undefined) {
                            let baseArr = []
                            let newArr = []
                            if (this.subTableName.length) {
                                this.subTableName.forEach((t) => baseArr.push(t?.referCondition))
                                this.activeName = this.subTableName[this.tabsIndex].id
                            }
                            newArr = baseArr.filter((str) => str.includes("@"))
                            newArr = newArr.map((item) => {
                                return item = item.split('@')[1]
                            })
                            newArr.forEach((str) => this.mainTableIndex?.[str] ? (this.ridObj[str] = this.mainTableIndex[str]) : null)
                            this.handleClick({index:this.tabsIndex})
                        }
                    }
                })
               
            },1500)
        }
    },
    components: {},
}
</script>
<style scoped lang="less">
.echarss {
    height: 100%;
    width: 100%;
    background: white;
}
.echars {
    width: 100%;
    // height: calc(100% - 40px);
    // height: calc(93% - 60px);
    height: calc(70% - 60px);
    background: white;
    position: relative;
}
.header-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.filter-left {
    display: flex;
}
.leftSearchBox {
    display: flex;
}
.echarsBoxd {
    width: 100%;
    background: white;
}
.chartsBox {
    position: absolute;
    border: 1px solid rgb(236, 236, 236);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .searnBox {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 10px;
        padding-right: 2px;
        // padding: 20px 50px;
        box-sizing: border-box;
    }
    .chartsSunBox {
        flex: 1;
    }
}
.nodata {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 200;
}
.backgSpanvalue {
    color: red;
    background: rgb(192, 192, 192);
    display: block;
    width: 100%;
    height: 100%;
}
.buttonBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
}
.echarsBox {
    width: 100%;
    height: 95%;
    .echBox {
        float: left;
        width: 600px;
        height: 100%;
    }
    .echarBox {
        float: left;
        width: calc(100% - 600px);
        height: 100%;
        .echbuttonBox {
            width: 100%;
            height: 60px;
            padding-left: 20px;
            box-sizing: border-box;
        }
        .echarsSunBox {
            width: 100%;
            height: calc(100% - 70px);
        }
    }
}
.posegBox {
    width: 100%;
    height: 60px;
}
.tablespanBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.searceBox {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .inpiutBox {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        box-sizing: border-box;
        span {
            display: block;
            width: 80px;
            padding-left: 5px;
            box-sizing: border-box;
        }
    }
    .buttonBox {
        width: 60px;
    }
}
</style>